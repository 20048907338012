import React from "react"
import Header from "./header.js"
import Footer from "./footer.js"
import styles from "./layout.module.styl"

export default ({ mode, children, page }) => (
  <div className={styles.main + " " + (mode ? styles[mode] : "") + " " + page}>
    <title>CYD | Homepage</title>
    <Header mode={mode} />
    {children}
    <Footer mode={mode} />
  </div>
)
