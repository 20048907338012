import React, { Component } from "react"
import { Link } from "gatsby"
import styles from "./header.module.styl"
import { event } from "./event.js"

export default props => (
  <nav
    id="nav"
    role="navigation"
    className={styles.nav + " " + (props.mode ? styles[props.mode] : "")}
  >
    <ul>
      <li>
        <Link
          to="/projects"
          activeClassName={styles.active}
          className="link--projects"
          partiallyActive={true}
        >
          Projects
        </Link>
      </li>
      <li>
        <Link
          to="/profile/"
          activeClassName={styles.active}
          className="link--about"
          partiallyActive={true}
        >
          Profile
        </Link>
      </li>
      <li>
        <Link
          to="/contact/"
          activeClassName={styles.active}
          className="link--contact"
          partiallyActive={true}
        >
          Contact
        </Link>
      </li>
      <li className={styles.home}>
        <Link to="/" activeClassName={styles.activeHome} className="link--home">
          <img src="/home.png" alt="HOme" />
        </Link>
      </li>
    </ul>
  </nav>
)
