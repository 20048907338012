import React from "react"
import { Link } from "gatsby"
import styles from "./footer.module.styl"

export default props => (
  <footer
    className={styles.footer + " " + (props.mode ? styles[props.mode] : "")}
  >
    <Link to="/">CYD © {new Date().getFullYear()} </Link>
    <Link to="/"> | </Link>
    <a
      target="_blank"
      href="https://beian.miit.gov.cn/"
      rel="noopener noreferrer"
    >
      湘ICP备19024512号-1
    </a>
  </footer>
)
